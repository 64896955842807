const node = [
    {
        id: 1,
        text: "Informe de certificación",
        name: "CertificationReport",
        icon: "flaticon2-chart text-success",
        permission: 'informeCertificacion'
    },
    {
        id: 2,
        text: "Informe de materiales",
        name: "MaterialReport",
        icon: "flaticon2-box-1 text-primary",
        permission: "informeMateriales"
    },
    {
        id: 3,
        text: "Tiempos de ejecucion",
        name: "EjecutionTime",
        icon: "flaticon2-chronometer text-danger",
        permission: "informeTiempos",
    },
    {
        id: 4,
        text: "Esquema operativo",
        name: "OperativeSchema",
        icon: "flaticon2-layers text-info",
        permission: "EsquemaOperativo"
    },
    {
        id: 5,
        text: "Recorrido",
        name: 'RecorridoReport',
        icon: 'flaticon2-rocket text-primary',
        permission: "informeRecorrido"
    },
    {
        id: 6,
        text: "Admin OT",
        name: 'AdminOT',
        icon: 'fa fa-object-group text-primary',
        permission: "informeAdminOT"
    },
    {
        id: 7,
        text: "Liquidación",
        name: 'Liquidacion',
        icon: 'la la-money-bill-wave-alt text-success',
        permission: "informeLiquidacion"
    }
];

export default node;